<script>
import cognitoMixin from '@/mixins/cognito.mixin'

/**
 * Initializes cognito
 */
export default defineNuxtComponent({
  mixins: [cognitoMixin],

  render () {
    return null
  },
})
</script>
